export const serviceOptions = () => {
  const items = [
    { label: 'BACnet', value: 'bacnet', category: 'account' },
    { label: 'Meter.co.uk', value: 'meteruk', category: 'account' },
    { label: 'Solis', value: 'solis', category: 'account' },
    { label: 'Solarman', value: 'solarman', category: 'account' },
    { label: 'Gridfetch', value: 'gridfetch', category: 'account' },
    { label: 'Smart Flow', value: 'smartflow', category: 'account' },
    { label: 'eLogBooks', value: 'elogbooks', category: 'account' },
    { label: 'Stark', value: 'stark', category: 'company' },
    { label: 'Siemens', value: 'siemens', category: 'company' },
    { label: 'Siemens Tem', value: 'siemenstem', category: 'company' },
    { label: 'Orsis', value: 'orsis', category: 'company' },
    { label: 'SSE Clarity', value: 'sse', category: 'company' },
    { label: 'Autometer', value: 'autometer', category: 'company' },
    { label: 'Schneider', value: 'schneider', category: 'company' },
    { label: 'IMServ Data Vision', value: 'imserv', category: 'company' },
    { label: 'CarloGavazziVmuc', value: 'carlogavazzivmuc', category: 'company' },
    { label: 'Schneider EGX', value: 'schneideregx', category: 'company' },
    { label: 'Sentinel', value: 'sentinel', category: 'company' },
    { label: 'Synapsys', value: 'synapsys', category: 'company' },
    { label: 'CXG Single Column', value: 'cxgsinglecolumn', category: 'company' },
    { label: 'CXG Multi Column', value: 'cxgmulticolumn', category: 'company' },
    { label: 'CXG Multi Time Column', value: 'cxgmultitimecolumn', category: 'company' },
    { label: 'Niagara N4', value: 'niagaran4', category: 'company' },
    { label: 'Elveco', value: 'elveco', category: 'company' },
    { label: 'SoClean', value: 'soclean', category: 'company' },
    { label: 'Octanise', value: 'octanise', category: 'company' },
    { label: 'Account Status', value: 'accountStatus', category: 'system' },
    { label: 'Deepki', value: 'deepki', category: 'company' },
    { label: 'Crown AMR', value: 'crownamr', category: 'company' }
  ];

  items.sort((a, b) => a.label.localeCompare(b.label));

  return items;
};
